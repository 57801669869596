<template>
  <b-container
    id="run-the-numbers"
    class="section mb-0 mb-md-4 pb-0 pb-md-1 mt-0 mt-sm-0 pt-2 pt-sm-0"
    :class="{ 'mt-0': $route.name === 'LandingB' }"
  >
    <b-row class="">
      <b-col cols="12" class="text-light">
        <h2 class="w-100 mb-0 mt-0 pt-xl-1 heading">
          Run the Numbers
        </h2>

        <p class="sub-heading mt-sm-1 mt-md-0 mb-4 d-none d-md-inline-block">
          Use the tool below to see how much you can earn each month with Trackd
        </p>

        <div class="run-section mt-sm-3 mt-md-0">
          <p class="sub-heading mt-sm-5 mt-md-0 mb-0 mb-sm-0 mb-md-3">
            Estimated Size of your Fanbase
          </p>

          <OFSlider :min="0" v-bind:max="1000000"></OFSlider>

          <p class="sub-heading mb-0">
            Choose your monthly fan subscription
          </p>

          <OFSliderCurrency
            :min="0"
            :max="50"
            v-bind:symbol="symbol"
          ></OFSliderCurrency>
        </div>

        <b-row>
          <b-col cols="12" class="mt-md-3 mt-lg-2 mt-xl-4">
            <img
              src="../assets/img/chipin-plus-text.svg"
              alt="ChipIn+"
              class="chipinplus-logo f-r d-none d-sm-none"
            />
            <p class="earn mb-1 pb-0">
              You could earn between {{ calculation }} each month<sup>*</sup>
            </p>
            <p class="earn-info mb-lg-4 mb-xl-2">
              <sup>*</sup>Based on estimate of between 1% and 5% of your
              followers subscribing to you with ChipIn+.
              <br class="d-none d-lg-block" />Estimated earnings figure does not
              include income from ChipIn tips.
            </p>
          </b-col>

          <b-col cols="12" class="text-center mt-2 mb-4 mb-lg-0">
            <b-button
              href="#"
              @click="redirectToSite"
              variant="info"
              class="expand-section-btn mx-auto mt-3 py-2 px-2 py-md-3 px-md-4"
            >
              Create your Free Profile Now
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from "axios";
import OFSlider from "./OFSlider.vue";
import OFSliderCurrency from "./OFSliderCurrency.vue";

function numberWithCommas(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export default {
  name: "RunTheNumbers",
  components: {
    OFSlider,
    OFSliderCurrency,
  },
  data() {
    return {
      symbol: "£",
      subscribers: 100,
      subscription_amount: 9.99,
    };
  },
  methods: {
    redirectToSite() {
      var url_append = ''

      if (
        this.$route.query.utm_campaign &&
        this.$route.query.utm_source &&
        this.$route.query.utm_medium
      ) {

        url_append += '&utm_campaign=' + this.$route.query.utm_campaign
        url_append += '&utm_source=' + this.$route.query.utm_source
        url_append += '&utm_medium=' + this.$route.query.utm_medium

      }

      window.location = process.env.VUE_APP_REDIRECT_URL + url_append


    },
  },
  computed: {
    calculation() {
      var sum_1 = this.subscribers * this.subscription_amount * 0.01 * 0.85;
      var sum_2 = this.subscribers * this.subscription_amount * 0.05 * 0.85;

      return (
        this.symbol +
        numberWithCommas(sum_1.toFixed(0).toString()) +
        " and " +
        this.symbol +
        numberWithCommas(sum_2.toFixed(0).toString())
      );
    },
  },
  created() {
    axios.get("/onboarding/get-location").then((res) => {
      this.symbol = res.data.symbol;
    });
  },
};
</script>

<style scoped>
.heading {
  font-size: 18px;
  font-weight: 900;
  line-height: 30px;
}

.sub-heading {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.tagline {
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
}

.section p.large {
  font-size: 24px;
}

.section p.small {
  font-size: 18px;
}

#run-the-numbers.section .chipinplus-logo {
  height: 28px;
  position: relative;
  top: -15px;
}

.fw-900 {
  font-weight: 900 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-100 {
  font-weight: 100 !important;
}

.rounded-10 {
  border-radius: 10px;
}

.rounded-12 {
  border-radius: 12px;
}

.fs-40 {
  font-size: 40px;
}

.fs-24 {
  font-size: 24px;
}

.fs-22 {
  font-size: 22px;
}

.fs-20 {
  font-size: 20px;
}

.fs-18 {
  font-size: 18px;
}

.fs-16 {
  font-size: 16px;
}

.fs-14 {
  font-size: 14px;
}

.f-r {
  float: right !important;
}

.no-underline {
  text-decoration: none;
}

.lh-30 {
  line-height: 30px !important;
}

.lh-40 {
  line-height: 40px !important;
}

.lh-90 {
  line-height: 90px !important;
}

.start-now {
  width: 245px;
  height: 35px;
}

.earn {
  font-size: 18px;
  font-weight: 900;
  line-height: 22px;
  color: #f2b471;
}

.earn-info {
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
}

.expand-section-btn {
  transform: translateY(0%);
  border-radius: 10px;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}

@media (min-width: 576px) {
  #run-the-numbers {
    padding-top: 0px;
  }

  #run-the-numbers.section .chipinplus-logo {
    height: 28px;
    margin-right: -6.3em;
    margin-top: 35px;
  }

  .heading {
    font-size: 32px;
    font-weight: 900;
    line-height: 90px;
  }

  .sub-heading {
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
  }

  .run {
    margin-left: -0.6em;
    margin-right: 0em;
  }

  .start-now {
    width: 346px;
    height: 48px;
  }

  .earn {
    font-size: 24px;
    font-weight: 900;
    line-height: 30px;
    color: #f2b471;
  }

  .earn-info {
    font-size: 18px;
    font-weight: 200;
    line-height: 25px;
  }
}

@media (min-width: 768px) {
  #run-the-numbers {
    padding-top: 0px;
  }

  #run-the-numbers.section .chipinplus-logo {
    height: 28px;
    margin-right: -6.3em;
    margin-top: 35px;
  }

  .heading {
    font-size: 32px;
    font-weight: 900;
    line-height: 90px;
  }

  .sub-heading {
    font-size: 20px;
    font-weight: 300;
    line-height: 40px;
  }

  .run {
    margin-left: -0.6em;
    margin-right: 0em;
  }

  .start-now {
    width: 346px;
    height: 48px;
  }

  .earn {
    font-size: 24px;
    font-weight: 900;
    line-height: 30px;
    color: #f2b471;
  }

  .earn-info {
    font-size: 18px;
    font-weight: 200;
    line-height: 25px;
  }

  .expand-section-btn {
    transform: translateY(0%);
    border-radius: 10px;
    font-weight: 900;
    font-size: 24px;
    line-height: 22px;
    text-align: center;
  }
}

@media (min-width: 992px) {
  #run-the-numbers {
    padding-top: 0px;
  }

  #run-the-numbers.section .chipinplus-logo {
    height: 28px;
    margin-right: -6.3em;
    margin-top: 35px;
  }

  .heading {
    font-size: 40px;
    font-weight: 900;
    line-height: 90px;
  }

  .sub-heading {
    font-size: 24px;
    font-weight: 300;
    line-height: 40px;
  }

  .run {
    margin-left: 1em;
    margin-right: 3.9em;
  }

  .start-now {
    width: 346px;
    height: 48px;
  }

  .earn {
    font-size: 24px;
    font-weight: 900;
    line-height: 30px;
    color: white;
  }

  .earn-info {
    font-size: 18px;
    font-weight: 200;
    line-height: 25px;
  }
}

@media (min-width: 1200px) {

  #run-the-numbers.section .chipinplus-logo {
    height: 28px;
    margin-top: 35px;
  }

  .heading {
    font-size: 40px;
    font-weight: 900;
    line-height: 80px;
  }

  .sub-heading {
    font-size: 24px;
    font-weight: 300;
    line-height: 40px;
  }


  .start-now {
    width: 346px;
    height: 48px;
  }

  .earn {
    font-size: 24px;
    font-weight: 900;
    line-height: 30px;
    color: white;
  }

  .earn-info {
    font-size: 18px;
    font-weight: 200;
    line-height: 25px;
  }
}

@media (min-width: 1400px) {
  .run {
    margin-left: -3em;
    margin-right: -3em;
  }
}

@media (min-width: 2000px) {

  .run {
    margin-left: 10em;
    margin-right: 10em;
  }
  
}
</style>
