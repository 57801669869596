<template>
  <div>
    <b-row class="">
      <b-col cols="12">
        <div class="slider-wrap">
          <VueSlideBar
            v-model="slider.value"
            :draggable="true"
            :data="slider.data"
            :range="slider.range"
            :processStyle="slider.processStyle"
            :lineHeight="slider.lineHeight"
            @callbackRange="callbackRange"
          >
            <template slot="tooltip" slot-scope="tooltip">
              <span v-if="tooltip"></span>
              <span tabindex="0" class="slider-handle">
                <span class="slider-value">{{ val }}</span>
              </span>
            </template>
          </VueSlideBar>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VueSlideBar from "vue-slide-bar";

export default {
  name: "OFSlider",
  props: ["min", "max", "symbol"],
  components: {
    VueSlideBar,
  },
  data() {
    return {
      rangeValue: {},
      slider: {
        value: 10,
        lineHeight: 10,
        range: [
          { label: this.symbol + "1" },
          { label: 2, isHide: true },
          { label: 3, isHide: true },
          { label: 4, isHide: true },
          { label: 5, isHide: true },
          { label: 6, isHide: true },
          { label: 7, isHide: true },
          { label: 8, isHide: true },
          { label: 9, isHide: true },
          { label: 10, isHide: true },
          { label: 11, isHide: true },
          { label: 12, isHide: true },
          { label: 13, isHide: true },
          { label: 14, isHide: true },
          { label: 15, isHide: true },
          { label: 16, isHide: true },
          { label: 17, isHide: true },
          { label: 18, isHide: true },
          { label: 19, isHide: true },
          { label: 20, isHide: true },
          { label: 21, isHide: true },
          { label: 22, isHide: true },
          { label: 23, isHide: true },
          { label: 24, isHide: true },
          { label: 25, isHide: true },
          { label: 26, isHide: true },
          { label: 27, isHide: true },
          { label: 28, isHide: true },
          { label: 29, isHide: true },
          { label: 30, isHide: true },
          { label: 31, isHide: true },
          { label: 32, isHide: true },
          { label: 33, isHide: true },
          { label: 34, isHide: true },
          { label: 35, isHide: true },
          { label: 36, isHide: true },
          { label: 37, isHide: true },
          { label: 38, isHide: true },
          { label: 39, isHide: true },
          { label: 40, isHide: true },
          { label: 41, isHide: true },
          { label: 42, isHide: true },
          { label: 43, isHide: true },
          { label: 44, isHide: true },
          { label: 45, isHide: true },
          { label: 46, isHide: true },
          { label: 47, isHide: true },
          { label: 48, isHide: true },
          { label: 49, isHide: true },
          { label: this.symbol + "50" },
        ],
        data: [
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
          9,
          10,
          11,
          12,
          13,
          14,
          15,
          16,
          17,
          18,
          19,
          20,
          21,
          22,
          23,
          24,
          25,
          26,
          27,
          28,
          29,
          30,
          31,
          32,
          33,
          34,
          35,
          36,
          37,
          38,
          39,
          40,
          41,
          42,
          43,
          44,
          45,
          46,
          47,
          48,
          49,
          50,
        ],
        processStyle: {
          backgroundColor: "transparent",
        },
      },
    };
  },
  methods: {
    callbackRange(val) {
      this.rangeValue = val;
      this.$parent.subscription_amount = this.slider.value;
    },
  },
  computed: {
    val() {
      if (this.rangeValue.isHide) {
        return this.symbol + this.rangeValue.label;
      } else {
        return this.rangeValue.label;
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
.fw-900 {
  font-weight: 900 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fs-24 {
  font-size: 24px;
}

.lh-90 {
  line-height: 90px !important;
}

.slider-wrap {
  margin: 28px calc(2% + 40px) 44px;
}

.vue-slide-bar {
  background-color: transparent !important;
  background-image: url("../assets/img/dashed-line.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.slider-handle {
  border-radius: 4px;
  cursor: pointer;
  width: 25px;
  height: 80px;
  top: -10px;
  touch-action: none;
  border: 5px solid #f2b471;
  position: absolute;
  left: 55px;
  border-radius: 10px;
  background: black;
}

.slider-handle:focus {
  outline-color: transparent !important;
  outline-style: none !important;
  outline-width: none !important;
}

.slider-value {
  position: relative;
  top: 85px;
  left: -22px;
  touch-action: none;
  font-weight: 900;
  font-size: 24px;
}

.slider-handle span {
  position: absolute;
  pointer-events: none;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 11px);
}

.vue-slide-bar-separate {
  content: "";
  position: absolute;
  width: 12px !important;
  height: 12px !important;
  border: none;
  background-color: transparent !important;
  border-radius: 1000px;
  left: -10px;
  top: -12px;
  color: inherit !important;
  font: inherit !important;
  width: 2px;
  height: 5px;
  cursor: pointer;
}

.vue-slide-bar-separate-text {
  text-align: center !important;
  position: absolute !important;
  white-space: nowrap !important;
  transform: translate(-50%, 0) !important;
  top: -17px !important;
  left: -22px !important;
  color: #fff !important;
  font-size: 24px;
}

.vue-slide-bar-separate + .vue-slide-bar-separate,
.vue-slide-bar-separate .vue-slide-bar-separate-text:nth-child(2) {
  left: 65px !important;
  font-weight: 900 !important;
  font-size: 24px;
}


/* Extra small devices (portrait phones, less than 576px) */
/* No media query for `xs` since this is the default in Bootstrap  */
@media (max-width: 575.98px) { 

  .slider-wrap {
    margin: 0px calc(2% + 40px) 10px;
  }

  .vue-slide-bar {
    background-color: transparent !important;
    background-image: url("../assets/img/dashed-line-mobile.png");
    background-repeat: no-repeat;
    background-size: contain;
  }

  .slider-value,
  .vue-slide-bar-separate-text {
    font-size: 12px;
  }

  .vue-slide-bar-separate-text {
    top: -11px !important;
  }

  .vue-slide-bar-tooltip-top {
    top: 0px !important;
  }

  .slider-handle {
    border-radius: 10px;
    border: 2px solid #F2B471;
    width: 20px;
    height: 58px;
  }

 }

/* Small devices (landscape phones, 576px and up)  
@media (min-width: 576px) { ... }*/

/* Medium devices (tablets, 768px and up)  
@media (min-width: 768px) { ... }*/

/* Large devices (desktops, 992px and up)  
@media (min-width: 992px) { ... }*/

/* Extra large devices (large desktops, 1200px and up)  
@media (min-width: 1200px) { ... }*/


</style>
