<template>
  <div>
    <b-row>
      <b-col cols="12">
        <div class="slider-wrap">
          <VueSlideBar
            v-model="slider.value"
            :draggable="true"
            :data="slider.data"
            :range="slider.range"
            :processStyle="slider.processStyle"
            :lineHeight="slider.lineHeight"
            @callbackRange="callbackRange"
          >
            <template slot="tooltip" slot-scope="tooltip">
              <span v-if="tooltip"></span>
              <span tabindex="0" class="slider-handle">
                <span class="slider-value">{{ val }}</span>
              </span>
            </template>
          </VueSlideBar>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VueSlideBar from "vue-slide-bar";

export default {
  name: "OFSlider",
  props: ["min", "max", "symbol"],
  components: {
    VueSlideBar,
  },
  data() {
    return {
      rangeValue: {},
      slider: {
        value: 10,
        lineHeight: 10,
        range: [
          { label: "10K" },
          { label: "20K", isHide: true },
          { label: "30K", isHide: true },
          { label: "40K", isHide: true },
          { label: "50K", isHide: true },
          { label: "60K", isHide: true },
          { label: "70K", isHide: true },
          { label: "80K", isHide: true },
          { label: "90K", isHide: true },
          { label: "100K", isHide: true },
          { label: "110K", isHide: true },
          { label: "120K", isHide: true },
          { label: "130K", isHide: true },
          { label: "140K", isHide: true },
          { label: "150K", isHide: true },
          { label: "160K", isHide: true },
          { label: "170K", isHide: true },
          { label: "180K", isHide: true },
          { label: "190K", isHide: true },
          { label: "200K", isHide: true },
          { label: "210K", isHide: true },
          { label: "220K", isHide: true },
          { label: "230K", isHide: true },
          { label: "240K", isHide: true },
          { label: "250K", isHide: true },
          { label: "260K", isHide: true },
          { label: "270K", isHide: true },
          { label: "280K", isHide: true },
          { label: "290K", isHide: true },
          { label: "300K", isHide: true },
          { label: "310K", isHide: true },
          { label: "320K", isHide: true },
          { label: "330K", isHide: true },
          { label: "340K", isHide: true },
          { label: "350K", isHide: true },
          { label: "360K", isHide: true },
          { label: "370K", isHide: true },
          { label: "380K", isHide: true },
          { label: "390K", isHide: true },
          { label: "400K", isHide: true },
          { label: "410K", isHide: true },
          { label: "420K", isHide: true },
          { label: "430K", isHide: true },
          { label: "440K", isHide: true },
          { label: "450K", isHide: true },
          { label: "460K", isHide: true },
          { label: "470K", isHide: true },
          { label: "480K", isHide: true },
          { label: "490K", isHide: true },
          { label: "500K", isHide: true },
          { label: "510K", isHide: true },
          { label: "520K", isHide: true },
          { label: "530K", isHide: true },
          { label: "540K", isHide: true },
          { label: "550K", isHide: true },
          { label: "560K", isHide: true },
          { label: "570K", isHide: true },
          { label: "580K", isHide: true },
          { label: "590K", isHide: true },
          { label: "600K", isHide: true },
          { label: "610K", isHide: true },
          { label: "620K", isHide: true },
          { label: "630K", isHide: true },
          { label: "640K", isHide: true },
          { label: "650K", isHide: true },
          { label: "660K", isHide: true },
          { label: "670K", isHide: true },
          { label: "680K", isHide: true },
          { label: "690K", isHide: true },
          { label: "700K", isHide: true },
          { label: "710K", isHide: true },
          { label: "720K", isHide: true },
          { label: "730K", isHide: true },
          { label: "740K", isHide: true },
          { label: "750K", isHide: true },
          { label: "760K", isHide: true },
          { label: "770K", isHide: true },
          { label: "780K", isHide: true },
          { label: "790K", isHide: true },
          { label: "800K", isHide: true },
          { label: "810K", isHide: true },
          { label: "820K", isHide: true },
          { label: "830K", isHide: true },
          { label: "840K", isHide: true },
          { label: "850K", isHide: true },
          { label: "860K", isHide: true },
          { label: "870K", isHide: true },
          { label: "880K", isHide: true },
          { label: "890K", isHide: true },
          { label: "900K", isHide: true },
          { label: "910K", isHide: true },
          { label: "920K", isHide: true },
          { label: "930K", isHide: true },
          { label: "940K", isHide: true },
          { label: "950K", isHide: true },
          { label: "960K", isHide: true },
          { label: "970K", isHide: true },
          { label: "980K", isHide: true },
          { label: "990K", isHide: true },
          { label: "1M" },
        ],
        data: [
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
          9,
          10,
          11,
          12,
          13,
          14,
          15,
          16,
          17,
          18,
          19,
          20,
          21,
          22,
          23,
          24,
          25,
          26,
          27,
          28,
          29,
          30,
          31,
          32,
          33,
          34,
          35,
          36,
          37,
          38,
          39,
          40,
          41,
          42,
          43,
          44,
          45,
          46,
          47,
          48,
          49,
          50,
          51,
          52,
          53,
          54,
          55,
          56,
          57,
          58,
          59,
          60,
          61,
          62,
          63,
          64,
          65,
          66,
          67,
          68,
          69,
          70,
          71,
          72,
          73,
          74,
          75,
          76,
          77,
          78,
          79,
          80,
          81,
          82,
          83,
          84,
          85,
          86,
          87,
          88,
          89,
          90,
          91,
          92,
          93,
          94,
          95,
          96,
          97,
          98,
          99,
          100,
        ],
        processStyle: {
          backgroundColor: "transparent",
        },
      },
    };
  },
  methods: {
    callbackRange(val) {
      this.rangeValue = val;
      this.$parent.subscribers = this.slider.value * 1000 * 10;
    },
  },
  computed: {
    val() {
      return this.rangeValue.label;
    },
  },
  mounted() {},
};
</script>

<style>
.fw-900 {
  font-weight: 900 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fs-24 {
  font-size: 24px;
}

.lh-90 {
  line-height: 90px !important;
}

.slider-wrap {
  margin: 28px calc(2% + 40px) 44px;
}

.vue-slide-bar {
  background-color: transparent !important;
  background-image: url("../assets/img/dashed-line.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.slider-handle {
  border-radius: 4px;
  cursor: pointer;
  width: 25px;
  height: 80px;
  top: -10px;
  touch-action: none;
  border: 5px solid #f2b471;
  position: absolute;
  left: 55px;
  border-radius: 10px;
  background: black;
}

.slider-handle:focus {
  outline-color: transparent !important;
  outline-style: none !important;
  outline-width: none !important;
}

.slider-value {
  position: relative;
  top: 85px;
  left: -22px;
  touch-action: none;
  font-weight: 900;
  font-size: 24px;
}

.slider-handle span {
  position: absolute;
  pointer-events: none;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 11px);
}

.vue-slide-bar-separate {
  content: "";
  position: absolute;
  width: 12px !important;
  height: 12px !important;
  border: none;
  background-color: transparent !important;
  border-radius: 1000px;
  left: -10px;
  top: -12px;
  color: inherit !important;
  font: inherit !important;
  width: 2px;
  height: 5px;
  cursor: pointer;
}

.vue-slide-bar-separate-text {
  text-align: center !important;
  position: absolute !important;
  white-space: nowrap !important;
  transform: translate(-50%, 0) !important;
  top: -17px !important;
  left: -22px !important;
  color: #fff !important;
  font-size: 24px;
}

.vue-slide-bar-separate + .vue-slide-bar-separate,
.vue-slide-bar-separate .vue-slide-bar-separate-text:nth-child(2) {
  left: 65px !important;
  font-weight: 900 !important;
  font-size: 24px;
}

/* Extra small devices (portrait phones, less than 576px) */
/* No media query for `xs` since this is the default in Bootstrap  */
@media (max-width: 575.98px) { 

  .slider-wrap {
    margin: 0px calc(2% + 40px) 10px;
  }

  .vue-slide-bar {
    background-color: transparent !important;
    background-image: url("../assets/img/dashed-line-mobile.png");
    background-repeat: no-repeat;
    background-size: contain;
  }

  .slider-value,
  .vue-slide-bar-separate-text {
    font-size: 12px;
  }

  .vue-slide-bar-separate-text {
    top: -11px !important;
  }

  .vue-slide-bar-tooltip-top {
    top: 0px !important;
  }

  .slider-handle {
    border-radius: 10px;
    border: 2px solid #F2B471;
    width: 20px;
    height: 58px;
  }

 }

/* Small devices (landscape phones, 576px and up)  
@media (min-width: 576px) { ... }*/

/* Medium devices (tablets, 768px and up)  
@media (min-width: 768px) { ... }*/

/* Large devices (desktops, 992px and up)  
@media (min-width: 992px) { ... }*/

/* Extra large devices (large desktops, 1200px and up)  
@media (min-width: 1200px) { ... }*/

</style>
